<template>
  <div>
    <help></help>
  </div>
</template>

<script>
  import Help from '../../components/Help/Help';

  export default {
    name: 'container',
    components: {
      help: Help
    },
    data() {
      return {

      };
    }
  };
</script>
